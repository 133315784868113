<template>
  <div style="background-color: #f5faff">
    <NavBar style="max-height=50px;" :pageName="pageName"></NavBar>
    <v-app>
      <!-- Cancal appointment Dialog Start  -->
      <div>
        <v-row justify="center">
          <v-dialog :persistent = "window_number != length-1" v-model="suggestion_dialogue" max-width="500">
            <v-window show-arrows v-model="window_number">
              <v-window-item v-for="(suggestion,i) in suggestionList" :key="`card-${i}`">
                <v-card color="white">
                  <div style="display: flex; flex-direction: column;">
                    <div class="suggestioncardClass" align="left">
                      <div style="height: 30px;">
                        <span class="suggestionClass">
                          Suggestion
                        </span>
                      </div>
                    </div>
                    <v-divider/>
                    <div class="suggestionFieldClass" align="left">
                      <div>
                        <span class="suggestionTitleClass">
                          {{suggestion.suggestion}}
                        </span>
                      </div>
                      <div>
                        <span align="left" class="suggestionDescriptionClass">
                          {{ suggestion.description }}
                        </span>
                      </div>
                    </div>
                    <div class="outcomeClass">
                      <div>
                        <v-btn color="success" :disabled="isClicked" @click="getNextSuggestion(suggestion.suggestion,'SUCCESS')" style="background-color: #1467BF;">Worked</v-btn>
                      </div>
                      <div>
                        <v-btn color="error" :disabled="isClicked" @click="getNextSuggestion(suggestion.suggestion,'FAIL')" style="background-color: #EB5757;">Not worked</v-btn>
                      </div>
                    </div>
                    <div class="navigateSuggestionClass">
                      <v-item-group v-model="window_number" class="text-center" mandatory >
                        <v-item v-for="n in length" :key="`btn-${n}`" v-slot="{ active, toggle }" >
                          <v-btn :input-value="active" icon @click="toggle" >
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
                        </v-item>
                      </v-item-group>
                    </div>
                  </div>
                </v-card>
              </v-window-item>
            </v-window>
          </v-dialog>
        </v-row>
      </div>
      <div style="background-color: #f5faff; height: 93vh !important;">
        <v-row cols="12">
          <v-col cols="2">
            <SideBar style="max-height=50px;" :pageName="pageName"></SideBar>
          </v-col>
          <v-col cols="10" style="overflow:scroll; height:90vh">
            <div style="margin: 10px 20px 20px 50px ">
              <div style=" display: flex; flex-direction: row; justify-content: space-between; vertical-align: middle;" >
                <div style="width: 30%" align="left">
                  <span class="patientTitleClass">
                    <h3>View Patient Devices</h3>
                  </span>
                </div>
              </div>
              <div style="margin-top: 1%; overflow: scroll; overflow-x: hidden; height: 45px 0px" v-if="!isLoading">
                <v-ca
                <table id="devices_table" class="tableClass" v-if="allDevices.length > 0">
                  <tr>
                    <th align="left" style="padding-left:8px">Inc. No.</th>
                    <th align="left">Device</th>
                    <th align="left">Browser</th>
                    <th align="left">Last Login</th>
                    <th align="left">Verification Status</th>
                    <th align="left">Assistance</th>
                    <th align="left">Suggestion worked</th>
                    <th align="left"></th>
                  </tr>
                  <tr v-for="device in allDevices" v-if="device.incident_number">
                    <td align="left">
                      {{device.incident_number}}
                    </td>
                    <td align="left">
                      {{device.device}}
                    </td>
                    <td align="left">
                      {{device.browser}}
                    </td>
                    <td align="left" v-if="device.last_login && typeof(device.last_login) == 'string'">
                      {{device.last_login.split("T")[0]}},{{device.last_login.split("T")[1].split(".")[0]}}
                    </td>
                    <td align="left" v-else-if="device.last_login">
                      {{new Date(device.last_login).toLocaleDateString() + " " + new Date(device.last_login).toLocaleTimeString()}}
                    </td>
                    <td align="left" v-else>
                      -
                    </td>
                    <td align="left">
                      <span v-if="device.tested == false" class="NotVerifiedClass">
                        Not Verified
                      </span>
                      <span v-else-if="device.verified == false && device.tested == true">
                        Verification Failed
                      </span>
                      <span v-else-if="device.verified == true && device.tested == true" class="VerifiedClass">
                        Verified
                      </span>
                      <span v-else>
                        -
                      </span>

                    </td>
                    <td align="left">
                      <span v-if="device.tested == true && device.verified == false && device.need_assistance != 0" class="supportRequiredClass">
                        Support Required
                      </span>
                      <span v-else-if="device.assisted_by">
                        Support Done
                      </span>
                      <span v-else-if="device.tested == true && device.verified == true">
                        Not needed
                      </span>
                      <span v-else-if="device.tested == false">
                        Not Tested
                      </span>
                      <span v-else>
                        -
                      </span>
                    </td>
                    <td align="left">
                      {{device.solution}}
                    </td>
                    <td align="left" v-if="device.incident_number">
                      <v-btn color="primary" @click="openSuggestion(device.incident_number,device.device,device.browser,device.test_meet_link)" style="background-color: #1467BF;">Suggestions</v-btn>
                    </td>
                    <td align="left" v-else>
                      N/A
                    </td>
                  </tr>
                </table>
                <div v-else>
                  <p>Sorry, No matching results found. </p>
                </div>
                <div v-if="pagination_list > 1">
                  <v-pagination circle v-model="page" :length="pagination_list" :total-visible="7" @input="findPage(page)"
                  ></v-pagination>
                </div>
              </div>
              <v-progress-circular indeterminate color="primary" v-else ></v-progress-circular>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-app>
  </div>
</template>
<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";
import DateRendered from "../Customer/dateRender.vue"
import TimeRender from '../Customer/TimeRender.vue';
export default {
  name: "AdminHome",
  data() {
    return {
      start_date: "",
      page:1,
      pageNum:"",
      pageName: "",
      user_id: "",
      currentUser: "",
      allDevices: [],
      pagination_list: [],
      isLoading: true,
      suggestion_dialogue : false,
      incident_id: "",
      outcomeList: ["FAIL","SUCCESS"],
      selected_outcome: "",
      meeting_link: "",
      //suggestionList: [{"suggestion":"Clear Cache","description":"Clear current browser cache"},{"suggestion":"Update browser","description":"Update current browser"},{"suggestion":"Install Opera Browser","description":"Install opera browser from playstore"},{"suggestion":"Install Chrome","description":"Install chrome from playstore"}]
      suggestionList: [],
      window_number: 0,
      length: 0,
      isClicked: false
    };
  },
  mounted() {

    this.pageName = "PatientDevices";
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
      this.$router.push({
        name: "AdminLogin",
      });
    } else {
      var data = {
        token : this.currentUser,
        typeOfUser : "ADMIN",
        patientId: this.$route.params['user_id'],
        page : 1,
        limit : 10
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + "/adminViewPatientDevices", data)
      .then((patientDevicesResponse) => {
        if(patientDevicesResponse.status == 200){
          this.allDevices = patientDevicesResponse.data.devices;
          this.user_id = patientDevicesResponse.data.data._id;
          this.isLoading = false;
        }
      })
      .catch((patientDevicesError) => {
        console.log(patientDevicesError);if (patientDevicesError.response.status == 401) {
          window.alert("Sorry !! You are unauthorized !!");
          this.$router.push({
            name: "AdminLogin",
          });
        } else {
          window.alert("Something went wrong...");
        }
      })
    }
  },
  components: {
    NavBar,
    DateRendered,
    TimeRender,
    SideBar
  },
  methods: {
    toggle(page)
    {
      this.window_number = page;
    },
    openSuggestion(incident_id,device_name,browser_name,meeting_link)
    {
      var token_body = {
        device: device_name,
        browser: browser_name,
        token : this.currentUser,
        typeOfUser : "ADMIN"
      };
      axios.post(process.env.VUE_APP_BACKEND_URL+"/readAllSuggestions",token_body)
      .then((allSuggestionResponse) =>{
        this.suggestionList = allSuggestionResponse.data.data;
        this.length = this.suggestionList.length;
        this.incident_id = incident_id;
        this.meeting_link = meeting_link;
        this.suggestion_dialogue = true;
        this.window_number = 0;

      })
      .catch((allSuggestionError) => {
        console.log(allSuggestionError);
      });
    },
    findPage(page_number)
    {
      console.log(page_number);
    },
    getNextSuggestion(suggestion,selected_outcome)
    {
      //axios call to save failed outcome, suggestion object, incident id is input
      this.isClicked = true;
      var incident_body = {
        incidentId: this.incident_id,
        suggestion: suggestion,
        meetLink : this.meeting_link,
        outcome: selected_outcome,
        assistedBy : this.user_id,
        token : this.currentUser,
        typeOfUser : "ADMIN"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL+"/saveSuggestionResponse", incident_body)
      .then((incidentResponse) =>{
        if(selected_outcome == "SUCCESS")
        {
          alert("Saved");
          this.device_name = "";
          this.browser_name = "";
          selected_outcome = "";
          this.$router.go();
        }
        else
        {
          console.log("didnt work, moving on");
          selected_outcome = "";
          if(this.window_number != this.length-1)
            this.window_number = this.window_number + 1;
          else
            window.alert("you've reached the end");
          this.isClicked = false;
        }
      })
      .catch((incidentError) =>{
        this.isClicked = false;
        console.log(incidentError);
      });
    },
  },
};
</script>

<style scoped>
td,
th {
  border: 0px;
}
td {
  padding: 10px;
  font-size: 8pt;
  font-weight: 700;
}
th {
  background-color: #1467bf;
  color: white;
  border: 0px;
  margin-left: 10%;
  position: sticky;
  top: 0;
  z-index: 0;
  height: 40px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
}

tr {
  border-bottom: #d4eeff 1px solid !important;
}
.tableClass {
  border-spacing: 0px;
  width: 100%;
  z-index: 0;
  background-color: white;
}
table tr:first-child th:first-child {
    border-top-left-radius: 10px;
}

table tr:first-child th:last-child {
    border-top-right-radius: 10px;
}
table > tr > td:last-child {
  white-space: nowrap;
}
.fb-customerchat{
  display: none;
}
.v-text-field.v-text-field--solo .v-input__control{
  min-height: 38px;
}
.video_join_class
{
  background-color: #1467bf;
  border-radius: 4px;
  padding: 4px;
}
.verified
{
  border-style: hidden solid hidden hidden;
  border-width: 15px;
  border-color: #46e888;
}
.Unverified
{
  border-style: hidden solid hidden hidden;
  border-width: 15px;
  border-color: #ed1d25;
}
.InProgress
{
  border-style: hidden solid hidden hidden;
  border-width: 15px;
  border-color: #e4e846;
}
.NeedAssistance
{
  border-style: hidden solid hidden hidden;
  border-width: 15px;
  border-color: #ed1d25;
}
.outcomeClass
{
  padding:  2% 10% 0% 15%;
  height: 10%;
  display:  flex;
  flex-direction: row;
  justify-content: space-around;
}
.suggestioncardClass
{
  padding:  0% 15%;
  margin-top: 30px;
  height: 10%;
}
.patientTitleClass {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}
.suggestionFieldClass {
  height:  10%;
  justify-content: left;
  display: flex;
  flex-direction: column;
  padding:  0% 15%;
}
.suggestionClass
{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
  height:  100px;
}
.suggestionTitleClass
{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.suggestionDescriptionClass
{
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.theme--light.v-divider
{
  border: 1px solid #C9E5FF;
  margin: 0px 15%;
}
.v-btn--active
{
  color: blue !important;
}
.theme--light.v-btn--active:before
{
   background-color: white !important;
}
.NotVerifiedClass {
  color: orange;
}
.VerifiedClass {
  color: black
}
.supportRequiredClass {
  color: red;
}
</style>